import axios from 'axios';
import eureka from 'eureka';
import { components, setting1, setting2, setting3, setting4, setting5 } from './mock.settings';
import { cdnURL } from '../features/constants/app.constants';

const { axiosCreate } = eureka.HttpWrapper;
const axiosWrapper = axiosCreate();
const axiosWithCSRF = axiosWrapper.instance;

export const fetchConfigSuccess = () => {
  return new Promise((resolve, reject) => {
    resolve({
      data: {
        config: {
          cdn: cdnURL,
        },
        'shell-ui': {
          name: 'eureka-shell-ui',
          location: `${cdnURL}/cic/eureka-shell-ui/1ea2f87`,
        },
        components,
      },
    });
  });
};

export const fetchConfigSuccessWithNoLocation = () => {
  return new Promise((resolve, reject) => {
    resolve({
      data: {
        config: {
          cdn: cdnURL,
        },
        'shell-ui': {
          name: 'eureka-shell-ui',
        },
        components,
      },
    });
  });
};

export const fetchConfigSuccessWithNoShell = () => {
  return new Promise((resolve, reject) => {
    resolve({
      data: {
        config: {
          cdn: cdnURL,
        },
        components,
      },
    });
  });
};

export const fetchConfigSuccessWithNoComp = () => {
  return new Promise((resolve, reject) => {
    resolve({
      data: {
        config: {
          cdn: cdnURL,
        },
        'shell-ui': {
          name: 'eureka-shell-ui',
          location: `${cdnURL}/cic/eureka-shell-ui/1ea2f87`,
        },
        components: [
          {
            name: 'comment1-ui',
            config: {
              app: 'comment',
              fragments: [
                {
                  name: 'Comments',
                  section: ['comments'],
                },
              ],
              routers: [],
            },
          },
        ].concat(),
      },
    });
  });
};

export const fetchConfigFailed = () => {
  return new Promise((resolve, reject) => {
    reject(new Error('fetchConfigFailed'));
  });
};

export const fetchAuthSuccess = () => {
  return new Promise((resolve, reject) => {
    resolve({
      name: 'sz.cn Shenzhen, Cn',
      email: 'sz.cn@sap.com',
      user_id: '31c3f3f3-4b57-44de-97a9-9ef101010972',
      auth_provider: 'colgate-dev1',
      auth_method: 'openid',
    });
  });
};

export const fetchAuthFailed401 = () => {
  return new Promise((resolve, reject) => {
    reject({
      request: {
        status: '401',
      },
    });
  });
};

export const fetchAuthFailedOthers = () => {
  return new Promise((resolve, reject) => {
    reject({
      request: {
        status: '500',
      },
    });
  });
};

export const fetchCsrfSuccess = () => {
  return new Promise((resolve, reject) => {
    resolve({ token: 'kIdEwgOMUiHIvZae' });
  });
};

export const fetchCsrfFailed = () => {
  return new Promise((resolve, reject) => {
    reject(new Error('fetchCsrfFailed'));
  });
};

export const fetchSettingsSuccess = () => {
  return new Promise((resolve, reject) => {
    resolve([setting1, setting2, setting3, setting4, setting5]);
  });
};

export const fetchSettingsFailedWithNoBasicSetup = () => {
  return new Promise((resolve, reject) => {
    resolve([null, setting2, setting3, setting4, setting5]);
  });
};

export const fetchFunctions = {
  fetchConfig() {
    return new Promise((resolve, reject) => {
      axios.get('/config.json').then(resolve, reject);
    });
  },
  fetchAuth() {
    return new Promise((resolve, reject) => {
      axios.get('/auth/user').then(resolve, reject);
    });
  },
  fetchCsrf() {
    return new Promise((resolve, reject) => {
      axios({
        url: `/auth/csrf-token`,
        method: 'POST', // or 'PUT'
        mode: 'cors',
        cache: 'no-cache',
        withCredentials: true,
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        // body: JSON.stringify(data),
      }).then(resolve, reject);
    });
  },
  fetchSettings() {
    return Promise.all([
      axiosWithCSRF.get('/api/crp-generic-biz-config/business/v1/basic-setup'),
      axiosWithCSRF.get('/api/user-configuration-plus/business/v1/users/me'),
      axiosWithCSRF.get('/api/user-configuration-plus/business/v1/users/roles'),
      axiosWithCSRF.get('/api/feature-toggle-wrapper/business/v1/feature-toggles'),
      axiosWithCSRF.get('/api/crp-generic-biz-config/business/v1/basic-setup/isProduction'),
    ]);
  },
  fetchUserCreate() {
    return new Promise((resolve, reject) => {
      axiosWithCSRF
        .get('/api/user-configuration-plus/business/v1/users/create')
        .then(resolve, reject);
    });
  },
  fetchUserDetails() {
    return new Promise((resolve, reject) => {
      axiosWithCSRF.get('/api/user-configuration-plus/business/v1/users/me').then(resolve, reject);
    });
  },
};

let usingMock = false;

export const switchToMock = (bool) => {
  usingMock = bool;
};

export default () => {
  if (usingMock) {
    return {
      usingMock: true,
      fetchConfig: () => {
        return new Promise((resolve, reject) => {
          axios.get('/config.json').then(resolve, reject);
        });
      },
      fetchAuth: fetchAuthSuccess,
      fetchCsrf: fetchCsrfSuccess,
      fetchSettings: fetchSettingsSuccess,
      fetchUserCreate: fetchSettingsSuccess,
    };
  } else {
    return {
      usingMock,
      ...fetchFunctions,
    };
  }
};
