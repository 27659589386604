import eurekaMgrs from '@eureka/ui-managers';
import '@ui5/webcomponents-icons/dist/copy';
import '@ui5/webcomponents-icons/dist/json-imports/Icons.js';
import '@ui5/webcomponents-icons/dist/log.js';
import '@ui5/webcomponents-icons/dist/headset';
import '@ui5/webcomponents-icons/dist/menu';
import '@ui5/webcomponents-icons/dist/menu2';
import '@ui5/webcomponents-icons/dist/product';
import '@ui5/webcomponents-icons/dist/sys-help';
import '@ui5/webcomponents-icons/dist/user-settings.js';
import {
  Button,
  ButtonDesign,
  Input,
  Label,
  Popover,
  ShellBar,
  ShellBarItem,
  StandardListItem, List
} from '@ui5/webcomponents-react';
import eureka from 'eureka';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LogoutForm from '../plugins/LogoutForm';
import { getAvatar } from '../plugins/profile.plugin';
import { searchInfo } from '../plugins/search.plugin';
import UserProfile from './UserProfile';
// import '@ui5/webcomponents/dist/features/InputSuggestions.js';
import logo from './logo';
import * as actions from './redux/actions';
import { PRP_106584_PRP_Integration_PX } from '../constants/featureFlags.constants';
import { DEFAULT_LANG, DEFAULT_THEME } from '../constants/pxFeedback.constants';

const { FragmentManager, ConfigManager } = eurekaMgrs;
const { getConfig, getLanguage } = ConfigManager;
const { withTranslation } = eureka.I18nProvider;
const { useTranslation } = eureka.I18nProvider;
const { eventBus } = eurekaMgrs;
const { addConfig, getFeatureToggle } = eurekaMgrs.ConfigManager;
const { Spinner } = eureka.components;
const { getThemeId } = eurekaMgrs.AppearanceManager;

/* istanbul ignore next */
export const UserProfileFragment = ({ config, history }) => {
  const domRef = useRef();
  useEffect(() => {
    const container = domRef.current;
    const fragments = FragmentManager.getFragmentsWithTag('shell-user_profile', config);
    let renderMap = null;
    if (container) {
      renderMap = FragmentManager.renderFragments({
        container,
        fragments,
        renderFragment: (fragmentContainer, fragment) => {
          fragment.render(fragmentContainer, history, {
            config,
            eventBus,
          });
        },
      });
    }
    return () => {
      if (container) {
        FragmentManager.unmountFragments({ container, renderMap });
      }
    };
  }, []);
  return (
    <div ref={domRef} className="user-profile-fragment" style={{ width: '100%', height: '100%' }}>
      <UserProfile config={config} eventBus={eventBus} />
    </div>
  );
};

export const handleSearchInput = (searchInput) => {
  var value = searchInput.value;
  var suggestionItems = [];

  if (value) {
    suggestionItems = searchInfo.searchList.filter((item) => {
      return item.text.toUpperCase().indexOf(value.toUpperCase()) === 0;
    });
  }

  // remove the previous suggestions
  [].slice.call(searchInput.children).forEach((child) => {
    searchInput.removeChild(child);
  });

  // add the new suggestions according to the  user input
  suggestionItems.forEach((item) => {
    var li = document.createElement('ui5-suggestion-item');
    li.text = item.text;
    searchInput.appendChild(li);
  });
};

export const handleProfileClick = ({ event, profileMenuRef }) => {
  profileMenuRef?.current?.showAt(event.detail.targetRef);
};

// const openProfileSetting = ({ event, config, t }) => {
//   closeProfileMenu();
//   eventBus.emit('dialog-show-hide', '', {
//     type: 'user-profile',
//     data: {
//       showDialog: true,
//       title: t('Header_ProfileSettings'),
//       content: () => {
//         return <UserProfileFragment config={config} history={window.history} />;
//       },
//     },
//   });
// };

const openProfileSetting = (event, config, t) => {
  closeProfileMenu();
  eventBus.emit('dialog-show-hide', '', {
    type: 'user-profile',
    data: {
      showDialog: true,
      // title: t('Header_ProfileSettings', 'Settings', { type: 'tit', desc: '' }),
      title: 'Header',
      content: () => {
        return <UserProfile config={config} eventBus={eventBus} />;
      },
    },
  });
};

const logout = () => {
  closeProfileMenu();

  eventBus.emit('dialog-show-hide', '', {
    type: 'user-logout',
    data: {
      showDialog: true,
      content: <LogoutForm eventBus={eventBus} />,
    },
  });
};

const closeProfileMenu = (event, profileMenuRef) => {
  profileMenuRef?.current?.close && profileMenuRef.current.close();
};

// const renderTopMenu = () => {};

export const handleSidenav = ({ showMenu, actions }) => {
  if (showMenu) {
    actions?.hideMenu?.call();
  } else {
    actions?.showMenu?.call();
  }
  eventBus.emit('sidenav-show-hide', null, { collapsed: showMenu });
  addConfig('SidenavCollapsed', showMenu);
};

export const getAppNameFromPath = (path) => {
  switch (path) {
    case 'manage-order-proposal':
      return 'Manage Order Proposals';
    case 'market-unit':
      return 'Manage Market Units';
    case 'manage-demand-channels':
      return 'Manage Demand Channels';
    case 'configuration-profiles':
      return 'Manage Configuration Profiles for Distribution Centers';
    case 'configuration-profiles-store':
      return 'Manage Configuration Profiles for Stores';
    case 'uom-category':
      return 'Manage Unit of Measure Categories';
    case 'config-assign-prod-loc':
      return 'Manage Assignments to Products and Locations for Distribution Centers';
    case 'config-assign-prod-loc-stores':
      return 'Manage Assignments to Products and Locations for Stores';
    case 'config-assign-supply-network':
      return 'Manage Assignments to Supply Networks for Distribution Centers';
    case 'manage-store-group':
      return 'Manage Store Groups';
    case 'replenishment-schedule':
      return 'Schedule Calculation of Order Proposals';
    case 'transfer-schedule':
      return 'Schedule Transfer of Order Proposals';
    case 'configuration':
      return 'Basic Setup';
    case 'manage-insight-conf':
      return 'Manage Insight Types';
    case 'insight-overview':
      return 'Insight Overview';
    case 'supplier-group':
      return 'Manage Supplier Groups';
    default:
      return 'Shell-ui';
  }
};

export const getCurrentAppVersion = (currentAppId) => {
  const { componentVersionMap } = getConfig('pxAPIObject') || { componentVersionMap: {} };
  let currentAppVersion = '';
  if (Object.keys(componentVersionMap).length > 0) {
    Object.keys(componentVersionMap).forEach((repo) => {
      if (componentVersionMap[repo].routers.includes(currentAppId)) {
        currentAppVersion = componentVersionMap[repo].commitId;
      }
    });
  }
  return currentAppVersion;
};

export const getCurrentAppContextData = (prevThemeId) => {
  const { language, themeId } = getConfig('CurrentUser') || {
    language: DEFAULT_LANG,
    themeId: DEFAULT_THEME,
  };
  const currentAppId = window.location.pathname.split('/')[1];
  const currentAppName = getAppNameFromPath(currentAppId);
  const currentAppVersion = getCurrentAppVersion('/' + currentAppId);

  let pxLanguage = 'EN';
  switch(language) {
    case 'zh-CN': pxLanguage = 'ZH-S'; break;
    case 'nb-NO': pxLanguage = 'NO'; break;
    default: pxLanguage = language && language.split('-') && language.split('-').length > 0 ? language.split('-')[0].toUpperCase() : 'EN'; break;
  }
  const currentContextData = {
    appId: currentAppId,
    appTitle: currentAppName,
    appVersion: currentAppVersion,
    technicalAppComponentId: currentAppId,
    appSupportInfo: 'IS-PRP',
    appFrameworkId: 'ui5/webcomponents',
    appFrameworkVersion: '1.17.0',
    theme: themeId,
    previousTheme: prevThemeId,
    languageTag: pxLanguage,
  };
  return currentContextData;
};

export const Header = ({ user, config, common, actions, history, settings }) => {
  // const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const profileMenuRef = useRef();
  const searchInputRef = useRef();
  const showPXBtnFeatureFlag = getFeatureToggle(PRP_106584_PRP_Integration_PX);
  let { showMenu } = common;

  const { t } = useTranslation();

  const shellBarRef = useRef();
  useEffect(() => {
    customElements.whenDefined('ui5-shellbar-crp-shell').then(() => {
      shellBarRef.current.accessibilityTexts = {
        profileButtonTitle: user?.email,
        logoTitle: t('SAP_Logo'),
      };
    });
  }, []);

  useEffect(() => {
    const { language } = getConfig('CurrentUser') || { language: 'en-US' };
    document.documentElement.setAttribute('lang', language ? language : 'en-US');

    addConfig('SidenavCollapsed', !showMenu);
    return () => {
      // TODO actions.stopWebsocket
    };
  }, []);

  const [initialLoadBIS, setInitialLoadBIS] = useState(true);
  function openBuiltInSupport() {
    window['sdk-react-dsx-everywhere'].openWindow();
    if (initialLoadBIS) {
      setTimeout(() => {
        window['sdk-react-dsx-everywhere'].refreshContext(() => {
          return new Promise((resolve) => {
            resolve({
              recommendedLinks: [
                {
                  text: t('Whats_New'),
                  url: 'https://help.sap.com/whats-new/98b6dd64a397437bbde06f178b030e60?locale=en-US',
                },
                {
                  text: t('SAP_Help_Portal'),
                  url: 'https://help.sap.com/docs/PRP?locale=en-US',
                },
                {
                  text: t('Product_Assistance'),
                  url: 'https://help.sap.com/docs/PRP/35430eca001c452ea8202e8f60958910/02dee4ac05554e4783a8ccbf7ad9f8e3.html',
                },
                {
                  text: t('Administration_Guide'),
                  url: 'https://help.sap.com/docs/PRP/6471b1898d8644f8921eb141943de948/5bfcfa5168fc48a18707fe2e4d8dc860.html',
                },
              ],
            });
          });
        });
      }, [2000]);
      setInitialLoadBIS(false);
    }
  }

  return (
    <div>
      <ShellBar
        className="shellbar"
        ref={shellBarRef}
        logo={<img alt={t('SAP_Logo')} tooltip={t('SAP_Logo')} src={logo} />}
        primaryTitle={t('CRP')}
        profile={getAvatar(user)}
        onProfileClick={(event) => handleProfileClick({ event, profileMenuRef })}
        showNotifications={false}
        searchField={
          <Input
            className="shellbar"
            ref={searchInputRef}
            onInput={(evt) => handleSearchInput(searchInputRef.current)}
            onSuggestionItemSelect={null}
            placeholder={t('Enter_a_term')}
            showSuggestions={true}
            type="Text"
            value={undefined}
            valueState="None"
          />
        }
        startButton={
          <Button
            tooltip={showMenu ? t('Collapse_Menu') : t('Expand_Menu')}
            icon={showMenu ? 'menu' : 'menu2'}
            design={ButtonDesign.Transparent}
            onClick={(event) => handleSidenav({ showMenu, actions })}
          />
        }
        stable-dom-ref="shell-bar"
        id="shell-bar"
      >
        <ShellBarItem
          id="help-button"
          icon="sys-help"
          text={t('Help')}
          tooltip={t('Help')}
          title={t('Help')}
          stable-dom-ref="app-help"
        />
        {showPXBtnFeatureFlag && (
          <ShellBarItem
            id="feedback-button"
            icon="feedback"
            text={t('Feedback')}
            tooltip={t('Feedback')}
            title={t('Feedback')}
            stable-dom-ref="app-feedback"
            onClick={() => {
              const { pxAPIInstance, isPxApiInitialized } = getConfig('pxAPIObject');
              const currentContextData = getCurrentAppContextData(pxAPIInstance.prevThemeId);
              if (isPxApiInitialized) {
                pxAPIInstance.openSurvey(currentContextData);
              }
            }}
          />
        )}
        <ShellBarItem
          onClick={openBuiltInSupport}
          id="built-in-support"
          icon="headset"
          text={t('Built_In_Support')}
          tooltip={t('Built_In_Support')}
          title={t('Built_In_Support')}
          stable-dom-ref="built-in-support"
        />
      </ShellBar>

      <Popover
        ref={profileMenuRef}
        allowTargetOverlap
        horizontalAlign="Right"
        placementType="Bottom"
        preventFocusRestore={false}
        initialFocus="profile-settings-option"
      >
        <div>
          <List onItemClick={e=>{ e.detail.item.id === 'profile-settings-option' ? openProfileSetting({ e, config, t }) : logout()}}>
          <StandardListItem
            id={"profile-settings-option"}
            data-key="1"
            icon={'user-settings'}
            infoState="None"
            selected={false}
            type="Active"
            style={{ margin: '3px', border: 'None' }}
            onClick={e=>openProfileSetting({ e, config, t })}
          >
            {t('ProfileSettings')}
          </StandardListItem>
          <StandardListItem
            id={"logout-option"}
            data-key="2"
            infoState="None"
            icon={'log'}
            selected={false}
            type="Active"
            style={{ border: 'None' }}
            onClick={logout}
          >
            {t('Logout')}
          </StandardListItem>
          </List>
        </div>
      </Popover>
    </div>
  );
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header));
