import {
  List,
  ListMode,
  StandardListItem,
  Button,
  ButtonDesign,
  Title,
  TitleLevel,
  MessageStrip,
  MessageStripDesign,
} from '@ui5/webcomponents-react';
import React, { useEffect, useState } from 'react';
import { string } from 'yup';
import {
  useTranslation,
  Spinner,
  getCurrentLanguage,
  setLanguage,
  configManagerSetLanguage,
  UtcMapping,
  MessageToast,
} from './eureka';
import { closeShellDialog } from '../../plugins/dialog.plugin';
import { fetchMe, patchMe, fetchLanguages } from './axios';
import LanguageAndRegion from './LanguageAndRegion';
import { TimeFormatOptions, TimeFormatTexts, TimeFormatTextsDefualts } from './options';
import UserAppearance from './UserAppearance';
import { setDocumentLang, getThemeById } from '../../../common/Utils';
import eurekaMgrs from '@eureka/ui-managers';
import UserAccount from './UserAccount';
import { defaultTimezoneKey } from '../../constants/timezone.constants';

const { getThemeId, setThemeId } = eurekaMgrs.AppearanceManager;
const initialData = {
  userName: '',
  valueStateFirstName: 'None',
  valueStateLastName: 'None',
  pictureId: null,
  email: '',
  phone: '',
  language: '',
  dateFormat: '',
  timeFormat: '',
  timeZone: defaultTimezoneKey,
};

const TimeZoneOptions = Object.keys(UtcMapping).map((key) => {
  return { ...UtcMapping[key], key };
});

const getFullUserName = (userName) => {
  if (userName && typeof userName === 'object') {
    return userName.familyNameFirst
      ? userName.lastName + ' ' + userName.firstName
      : userName.firstName + ' ' + userName.lastName;
  }
};
const userProfileLanguageRegion = 'user-profile_language_region';
const UserProfileAppearance = 'user-profile_appearance';

export default function UserProfile({ config, eventBus }) {
  const { t } = useTranslation('shell');
  const [isLoading, setLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState(userProfileLanguageRegion);
  const [data, setData] = useState({ ...initialData });
  const [originalData, setOriginalData] = useState({ ...initialData });
  const [languages, setLanguages] = useState([]);
  const [timeFormatIndex, setTimeFormatIndex] = useState(0);

  const [showErrorInUserNameMessage, setShowErrorInUserNameMessage] = useState(false);

  const nameValidationSchema = string().matches(
    /^[a-zA-ZÄÖÜßäöü0-9-_ ]+$/,
    t('Only_alphabets,_digits,<space>,underscore,ampersand _and_hyphen_are_allowed_for_this_field'),
  );

  useEffect(() => {
    setTimeFormatIndex(TimeFormatOptions.indexOf(data.timeFormat));
  }, [data.timeFormat]);

  const handleErrorMessageStripClose = () => {
    setShowErrorInUserNameMessage(false);
  };

  useEffect(() => {
    const getData = () => {
      setLoading(true);
      return Promise.all([fetchMe(), fetchLanguages(getCurrentLanguage())])
        .then((results) => {
          const me = results[0].data;
          setLanguage(me.language);
          setThemeId(me.themeId);
          setData({
            ...me,
            valueStateFirstName: 'None',
            valueStateLastName: 'None',
          });
          setOriginalData({
            ...me,
            valueStateFirstName: 'None',
            valueStateLastName: 'None',
          });
          setLanguages(results[1].data || []);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    getData();
  }, []);

  /* istanbul ignore next */
  // trigger re-render in mfes when user profile have updates
  const notifyOthers = (user, languageChanged, themeChanged) => {
    try {
      if (languageChanged) {
        // tell other mfes, language is changed, only update translations,
        eventBus?.emit('i18n-update', '', user?.language || 'en-US');
        document.lang = user?.language || 'en-US';
        document.documentElement.setAttribute('lang', user?.language || 'en-US');
        window.location.reload();
      }
      if (themeChanged) {
        // tell other mfes, theme is changed
        // no reload in this case
        const themeId = user?.themeId || 'sap_horizon';
        eventBus?.emit('appearance-update', 'user theme', { themeChanged: true, themeId });
      }
      // tell other mfes, user profile is changed, mfe reloads
      eventBus?.emit('configuration-updated', 'user profile', {
        key: 'userProfile',
        data: user,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancel = () => {
    closeShellDialog();
    setShowErrorInUserNameMessage(false);
    setData({
      ...originalData,
    });
  };

  /* istanbul ignore next */
  const onDataPropChange = (value, propName) => {
    if (propName.indexOf('.') > 0) {
      const [objName, subPropName] = propName.split('.');
      setData((prevData) => {
        return {
          ...prevData,
          [objName]: {
            ...data[objName],
            [subPropName]: value,
          },
        };
      });
    } else {
      setData((prevData) => {
        return {
          ...prevData,
          [propName]: value,
        };
      });
    }
  };

  /* istanbul ignore next */
  // TODO: Add test cases for save
  const handleSave = () => {
    const differ = {};
    let languageChanged = false;
    let themeChanged = false;
    Object.keys(data).forEach((key) => {
      if (originalData[key] !== data[key]) {
        differ[key] = data[key];
        if (key === 'language') {
          languageChanged = true;
        }
        if (key === 'themeId') {
          themeChanged = true;
        }
      }
    });
    if (Object.keys(differ).length > 0) {
      let current = parseInt(data.userUpdateState);
      current = isNaN(current) ? 1 : current;
      differ.userUpdateState = current + 1;
    }
    try {
      setLoading(true);
      nameValidationSchema.validateSync(data.userName.firstName + ' ' + data.userName.lastName);
      return (
        patchMe({ ...differ })
          .then((result) => {
            setLoading(true);
            const user = result.data;
            console.log(user);
            setOriginalData({ ...user });
            setData({ ...user });
            notifyOthers(user, languageChanged, themeChanged);

            if (languageChanged) {
              setLanguage(data.language);
              configManagerSetLanguage(data.language);
            }
            MessageToast.success(
              t('UserProfile_Msg_UpdateSuccess', 'Update success', { type: 'msg', desc: '' }),
            );
            closeShellDialog();
          })
          // on language change, update language options list
          .then(() => {
            fetchLanguages(data.language).then((result) => {
              setLanguages(result.data || []);
            });
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setData({
              ...originalData,
            });
            MessageToast.error(
              t('UserProfile_Msg_UpdateFailed', 'Update fail', { type: 'msg', desc: '' }),
            );
            setLoading(false);
          })
      );
    } catch (error) {
      setLoading(false);
      setShowErrorInUserNameMessage(true);
    }
  };

  return (
    <div>
      <Title level={TitleLevel.H3} style={{ padding: '16px 24px', fontSize: '16px' }}>
        {t('UserProfile_ProfileSettings', 'Settings', { type: 'tit', desc: '' })}
      </Title>
      <div style={{ borderBottom: '1px solid #e8e8e8', borderTop: '1px solid #e8e8e8' }}>
        <div
          style={{
            display: 'flex',
            width: '636px',
            height: '492px',
            minHeight: '330px',
            padding: 0,
          }}
        >
          <List
            style={{ width: '219px' }}
            mode={ListMode.SingleSelect}
            noDataText={t('UserProfile_Msg_NoDataAvailable', 'No data available', {
              type: 'msg',
              desc: '',
            })}
            onItemClick={(evt) => {
              setCurrentItem(evt.detail.item.id);
            }}
          >
            <StandardListItem
              id="user-profile_account"
              icon="employee"
              selected={currentItem === 'user-profile_account'}
              description={getFullUserName(data.userName)}
            >
              {t('UserProfile_UserAccount')}
            </StandardListItem>
            <StandardListItem
              icon="globe"
              id={userProfileLanguageRegion}
              selected={currentItem === userProfileLanguageRegion}
              description={`${(
                (data.language && data.language.split('-')[0]) ||
                '...'
              ).toUpperCase()} | ${
                t(
                  TimeFormatTexts[timeFormatIndex] || '...',
                  TimeFormatTextsDefualts[timeFormatIndex],
                  { type: 'tit', desc: '' },
                ) || '...'
              }`}
            >
              {t('UserProfile_LanguageAndRegion', 'Language and Region', {
                type: 'mit',
                desc: '',
              })}
            </StandardListItem>
            <StandardListItem
              icon="palette"
              id={UserProfileAppearance}
              selected={currentItem === UserProfileAppearance}
              // description={`${(getThemeId() || '...').toUpperCase()}`}
              description={getThemeById(getThemeId())?.displayName}
            >
              {t('UserProfile_Appearance', 'Appearance', { type: 'mit', desc: '' })}
            </StandardListItem>
          </List>
          <section
            style={{
              flexGrow: 1,
              width: '460px',
              padding: '1rem',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              borderLeft: '1px solid #e8e8e8',
            }}
          >
            {currentItem === 'user-profile_account' && (
              <UserAccount
                data={data}
                setData={setData}
                timezones={TimeZoneOptions}
                onDataPropChange={onDataPropChange}
              />
            )}
            {currentItem === userProfileLanguageRegion && (
              <LanguageAndRegion
                data={data}
                languages={languages}
                onDataPropChange={onDataPropChange}
              />
            )}
            {currentItem === UserProfileAppearance && (
              <UserAppearance data={data} theme={languages} onDataPropChange={onDataPropChange} />
            )}
          </section>
        </div>
      </div>
      {showErrorInUserNameMessage && (
        <MessageStrip
          data-testid="errorMessageStrip"
          design={MessageStripDesign.Negative}
          onClose={handleErrorMessageStripClose}
          hideIcon={false}
        >
          {t('INVALID_USERNAME')}
        </MessageStrip>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '60px',
        }}
      >
        <Button
          disabled={JSON.stringify(data) === JSON.stringify(originalData)}
          design={ButtonDesign.Emphasized}
          onClick={handleSave}
          data-testid="saveButton"
        >
          {t('Save', 'Save', { type: 'but', desc: '' })}
        </Button>
        <Button
          style={{
            marginLeft: '1rem',
            marginRight: '0.5rem',
          }}
          onClick={handleCancel}
          data-testid="cancelButton"
        >
          {t('Cancel', 'Cancel', { type: 'but', desc: '' })}
        </Button>
      </div>

      {isLoading && (
        <div
          style={{
            top: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            background: 'rgba(0, 0, 0, 0.01)',
            zIndex: 999,
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
}
